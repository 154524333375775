<template>
  <div class="py-2 border-t border-black">
    <h4
      class="text-center relative cursor-pointer text-black text-sm font-bold py-2 flex items-center justify-center"
      v-on:click="toggleOpen"
    >
      <span class="flex-1">{{ title }}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 absolute right-0"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
        :class="{ 'rotate-180': open }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </h4>

    <div v-if="open">
      <div class="max-h-[470px] overflow-auto">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardOverlayItem",
  props: ["title"],
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>

<style></style>
